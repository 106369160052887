<template>
    <v-dialog v-model="parameters.dialog.show" max-width="350">
        <v-card light>
            <v-card-title
                class="title"
                :style="{ color: parameters.dialog.titleColor }"
            >
                {{ parameters.dialog.titleText }}
            </v-card-title>
            <v-card-text class="text-left" v-html="parameters.dialog.text">
            </v-card-text>
            <v-card-actions>
                <v-spacer v-if="!parameters.dialog.confirmBtn"></v-spacer>
                <v-btn
                    @click="cancel"
                    :absolute="parameters.dialog.confirmBtn"
                    :left="parameters.dialog.confirmBtn"
                    elevation="0"
                    text
                    :color="parameters.primaryColor"
                >
                    {{ parameters.dialog.cancelText }}
                </v-btn>
                <v-btn
                    v-if="parameters.dialog.confirmBtn"
                    @click="confirm"
                    :color="parameters.primaryColor"
                    dark
                    class="white--text"
                >
                    {{ parameters.dialog.confirmText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    },
    methods: {
        cancel() {
            if (this.parameters.dialog.cancelAction != null) {
                this.parameters.dialog.cancelAction();
            }
            this.$store.dispatch("parameters/resetDialog");
        },
        confirm() {
            if (this.parameters.dialog.confirmAction != null) {
                this.parameters.dialog.confirmAction();
            }
            this.$store.dispatch("parameters/resetDialog");
        },
    },
};
</script>

<style>
</style>